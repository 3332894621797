<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 px-0">
          <modal-actions
            @close="$router.push({ name: 'technical-capacity' })"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
          <span class="h2 text-secondary"
            ><i class="mdi mdi-rocket"></i> Modifier composant</span
          >
          <div>
            <b-button pill>Confirmer</b-button>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <!-- First Col -->

        <div class="col-12 col-md-4 mb-4">
          <div class="shdow mr-2 w-100 h-100">
            <b-form-group
              label-cols="12"
              label="Libellé"
              label-class="font-weight-bold"
            >
              <b-form-input type="text"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Capacite technique"
              label-class="font-weight-bold"
            >
              <b-form-select
                v-model="selected"
                :options="['capacite 1', 'capacite 2']"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Architecture composant"
              label-class="font-weight-bold"
            >
              <b-form-select
                v-model="selected"
                :options="['Architecture 1', 'Architecture 2']"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Ligne métier"
              label-class="font-weight-bold"
            >
              <b-form-select
                v-model="selected"
                :options="['ligne 1', 'ligne 2']"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>

        <!-- Second Col -->
        <div class="col-12 col-md-4 mb-4">
          <div class="shdow pr-2 w-100 h-100">
            <b-form-group
              label-cols="12"
              label="Editeur"
              label-class="font-weight-bold"
            >
              <b-form-input type="text"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Status"
              label-class="font-weight-bold"
            >
              <b-form-select
                :options="['Propose', 'Non valide']"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Type composant"
              label-class="font-weight-bold"
            >
              <b-form-select :options="['BDO']"></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Portée composant"
              label-class="font-weight-bold"
            >
              <b-form-select :options="['Local']"></b-form-select>
            </b-form-group>
            <div class="row mt-4">
              <div class="col">
                <span class="font-weight-bold">Taux de remplissage</span>
              </div>
              <div class="col">
                <simple-slider />
              </div>
            </div>
          </div>
        </div>

        <!-- Third col -->
        <!-- Couvertute start -->

        <div class="col-12 col-md-4 mb-4">
          <div class="shdow w-100 h-100">
            <div class="w-100 m-0 p-0 text-secondary">
              <p class="h4 mb-4"><i class="mdi mdi-file"></i> Couverture</p>
            </div>
            <div class="row m-0 p-0">
              <div
                class="
                  col-12
                  d-flex
                  justify-content-between
                  align-items-center
                  mb-3
                  px-0
                "
              >
                <span class="mb-0 d-inline-block">Couverture</span>
                <b-button variant="light" pill size="xs"
                  ><i class="mdi mdi-plus"></i
                ></b-button>
              </div>
            </div>
            <div class="row m-0 p-0">
              <div
                style="height: 50px; position: relative; overflow: visible"
                class="shdow"
              >
                <p><country-flag country="it" size="small" /> Italie</p>
                <div style="position: absolute; top: -5px; right: -5px">
                  <span style="cursor: pointer"
                    ><i class="mdi mdi-close-circle text-danger"></i
                  ></span>
                </div>
              </div>
              <div
                style="height: 50px; position: relative; overflow: visible"
                class="shdow"
              >
                <p><country-flag country="fr" size="small" /> France</p>
                <div style="position: absolute; top: -5px; right: -5px">
                  <span style="cursor: pointer"
                    ><i class="mdi mdi-close-circle text-danger"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Couverture end -->
      </div>
    </b-card>

    <b-card class="card-table">
      <!-- Tabs -->
      <b-tabs class="tab-solid tab-solid-primary mt-5">
        <b-tab title="Référents">
          <div class="d-flex justify-content-end pr-4">
            <b-button variant="secondary">Valider</b-button>
          </div>
          <div class="row p-0 m-0">
            <div class="col-12 mb-3 d-flex justify-content-end"></div>
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                m-0
                p-0
                mr-4
              "
            >
              <div>
                <p>Sponsor</p>
              </div>
              <div
                style="
                  background: black;
                  height: 100px;
                  width: 100px;
                  border-radius: 50px;
                  position: relative;
                "
                class="
                  accnt-log
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <i
                  style="color: white; font-size: 40px"
                  class="mdi mdi-account-outline"
                ></i>
                <div style="position: absolute; bottom: -8px; right: 5px">
                  <i
                    style="
                      color: blue;
                      font-size: 25px;
                      background: white;
                      border-radius: 15px;
                    "
                    class="mdi mdi-plus-circle"
                  ></i>
                </div>
              </div>
              <div>
                <p>Steewe EWORE</p>
              </div>
            </div>
            <!-- second sponser -->

            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                m-0
                p-0
                mr-4
              "
            >
              <div>
                <p>Sponsor</p>
              </div>
              <div
                style="
                  background: black;
                  height: 100px;
                  width: 100px;
                  border-radius: 50px;
                  position: relative;
                "
                class="
                  accnt-log
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <i
                  style="color: white; font-size: 40px"
                  class="mdi mdi-account-outline"
                ></i>
                <div style="position: absolute; bottom: -8px; right: 5px">
                  <i
                    style="
                      color: blue;
                      font-size: 25px;
                      background: white;
                      border-radius: 15px;
                    "
                    class="mdi mdi-plus-circle"
                  ></i>
                </div>
              </div>
              <div>
                <p>John doe</p>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Version">
          <div class="d-flex justify-content-end">
            <b-button
              variant="secondary"
              class="align-btn-table"
              @click="addTableRow"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter une version</b-button
            >
          </div>
          <VersionTable
            @editItem="$router.push({ name: 'edit-vision' })"
            @showItem="$router.push({ name: 'show-vision' })"
            @onStatusChange="(e) => onStatusChange(e)"
            class="mt-3"
            :fields="versions.tableFields"
            :items="versions.tableItems"
          />
        </b-tab>
        <b-tab title="Les applications supportées">
          <div class="d-flex justify-content-end">
            <b-button
              variant="secondary"
              class="align-btn-table"
              @click="addAppRow"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter une application</b-button
            >
          </div>
          <application-table
            @editItem="$router.push({ name: 'edit-vision' })"
            @showItem="$router.push({ name: 'show-vision' })"
            class="mt-3"
            :fields="apps.tableFields"
            :items="apps.tableItems"
          />
        </b-tab>
        <b-tab title="Transformation">
          <div class="d-flex justify-content-end">
            <b-button
              variant="secondary"
              class="align-btn-table"
              @click="addTransRow"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter une transformation</b-button
            >
          </div>
          <transformation-table
            @editItem="$router.push({ name: 'edit-vision' })"
            @showItem="$router.push({ name: 'show-vision' })"
            class="mt-3"
            :fields="trans.tableFields"
            :items="trans.tableItems"
          />
        </b-tab>
      </b-tabs>

      <!-- End Tabs -->
    </b-card>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import SimpleSlider from "@/components/forms/sliders/simpleSlider.vue";
import ModalActions from "@/components/custom/ModalActions.vue";

import TransformationTable from "./components/TransformationTable.vue";
import ApplicationTable from "./components/ApplicationTable.vue";
import VersionTable from "./components/VesionTable.vue";

export default {
  components: {
    CountryFlag,
    SimpleSlider,
    ModalActions,
    TransformationTable,
    ApplicationTable,
    VersionTable,
  },
  data: () => ({
    versions: {
      tableItems: [
        {
          version: "Version 1",
          description: "version description",
          status_version: "En Cours",
          actions: ["edit", "delete"],
        },
        {
          version: "Version 2",
          description: "version 2 description",
          status_version: "Obsolete",
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "version", label: "Version", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "status_version", label: "Statut version", sortable: true },
        { key: "actions", label: "" },
      ],
    },

    apps: {
      tableItems: [
        {
          ref: "ref 1",
          libelle: "libelle 1",
          capacite_si: "cap 1",
          status: "rejected",
          po: "po ",
          taux: 45,
          actions: ["edit", "delete"],
        },
        {
          ref: "ref 2",
          libelle: "libelle 2 ",
          capacite_si: "cap 2",
          status: "accepted",
          po: "po",
          taux: 68,
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "ref", label: "Ref SI", sortable: true },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "capacite_si", label: "Capacité SI", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        { key: "actions", label: "" },
      ],
    },

    trans: {
      tableItems: [
        {
          fdr: "fdr 1",
          initiatives: "initiatives 1",
          capacite_si: "cap 1",
          po: "po 1",
          patrimoine: "patrimoine 1",
          type_compact: "suppression",
          des_compact: "description Iompact 1",
          date_fin: "22/12/2021",
          actions: ["edit", "delete"],
        },
        {
          fdr: "fdr 2",
          initiatives: "initiatives 2 ",
          capacite_si: "cap 2",
          po: "po 2",
          patrimoine: "patrimoine 2",
          type_compact: "creation",
          des_compact: "description Iompact 2",
          date_fin: "31/12/2021",
          actions: ["edit", "delete"],
        },

        {
          fdr: "fdr 3",
          initiatives: "initiatives 3 ",
          capacite_si: "cap 3",
          po: "po 3",
          patrimoine: "patrimoine 3",
          type_compact: "evolution",
          des_compact: "description Iompact 3",
          date_fin: "13/01/2022",
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "fdr", label: "Feuilles de route", sortable: true },
        { key: "initiatives", label: "Initiative", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "type_compact", label: "Type impact", sortable: true },
        { key: "des_compact", label: "Description impact", sortable: true },
        { key: "date_fin", label: "Date de fin", sortable: true },
        { key: "actions", label: "" },
      ],
    },
  }),
};
</script>
